

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { container } from '../templates/order-result-redirect.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';
import { IPage } from '../models/page.model';
import { getNodes } from '../utils/get-nodes';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';
import { getUserTokenData } from '../utils/get-user-token-data';
import { getPagePathname } from '../utils/get-page-pathname';

import ThemeStyle from '../components/atoms/theme-style';
import Loader from '../components/atoms/loader';

interface IOrderResultRedirectProps {
    readonly data: {
        allThemeSetting: IQueryAllResult<ISetting>;
        allPage: IQueryAllResult<Pick<IPage, 'type' | 'pathname' | 'locale'>>;
    };
}

const OrderResultRedirect: React.FC<IOrderResultRedirectProps> = ({ data }) => {
    const { locale } = usePageContext();
    const { allThemeSetting, allPage } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    useEffect(() => {
        const orderLanguage = localStorage.getItem('orderLanguage') || locale;
        const userType = getUserTokenData()?.userType;
        const pages = getNodes(allPage);
        const search = window.location.search;

        if (!userType) {
            navigate('/', { replace: true });
            return;
        }

        if (userType === 'candidate') {
            const pathname = getPagePathname(pages, orderLanguage, 'candidate-order-result');
            navigate(`${pathname}${search}`, { replace: true });
        }

        if (userType === 'client') {
            const pathname = getPagePathname(pages, orderLanguage, 'client-order-result');
            navigate(`${pathname}${search}`, { replace: true });
        }
    }, [allPage, locale]);

    return (
        <div className={container}>
            <ThemeStyle style={customStyle} />
            <Loader />
        </div>
    );
};

export const query = graphql`
    query {
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
        allPage(filter: { type: { in: ["client-order-result", "candidate-order-result"] } }) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default OrderResultRedirect;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';

const sectionComponents: Record<string, any> = {
};